/** 
* Generated via google-webfonts-helper
* https://google-webfonts-helper.herokuapp.com/fonts/ibm-plex-sans?subsets=latin
*/

/* ibm-plex-sans-300 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./ibm-plex-sans/ibm-plex-sans-v9-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
      url('./ibm-plex-sans/ibm-plex-sans-v9-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('./ibm-plex-sans/ibm-plex-sans-v9-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
      url('./ibm-plex-sans/ibm-plex-sans-v9-latin-300.woff') format('woff'), /* Modern Browsers */
      url('./ibm-plex-sans/ibm-plex-sans-v9-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
      url('./ibm-plex-sans/ibm-plex-sans-v9-latin-300.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-regular - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./ibm-plex-sans/ibm-plex-sans-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
      url('./ibm-plex-sans/ibm-plex-sans-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('./ibm-plex-sans/ibm-plex-sans-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
      url('./ibm-plex-sans/ibm-plex-sans-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
      url('./ibm-plex-sans/ibm-plex-sans-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
      url('./ibm-plex-sans/ibm-plex-sans-v9-latin-regular.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-700 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./ibm-plex-sans/ibm-plex-sans-v9-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
      url('./ibm-plex-sans/ibm-plex-sans-v9-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('./ibm-plex-sans/ibm-plex-sans-v9-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
      url('./ibm-plex-sans/ibm-plex-sans-v9-latin-700.woff') format('woff'), /* Modern Browsers */
      url('./ibm-plex-sans/ibm-plex-sans-v9-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
      url('./ibm-plex-sans/ibm-plex-sans-v9-latin-700.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
