.icon-button {
  color: white;
  background-color: var(--bright-background);
  border: none;
  border-radius: 10px;
  cursor: pointer;

  width: 50px;
  height: 50px;

  display: grid;
  place-items: center;
  font-size: 30px;

  transition: background-color 0.2s ease;
}

.icon-button:hover {
  background-color: var(--bright-background-hover);
}

@media print {
  .icon-button {
    display: none;
  }
}
