@import url("fonts/ibm-plex-sans.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: hidden;

  --argument-width: 12.5vw;
}

* {
  transition: background 0.2s, color 0.2s, border-color 0.2s;
}

body.light {
  color: rgb(61, 71, 82);
  background: #f3f6f9;

  --dark-background: #e2edf8;
  --bright-border: #007fff;
  --bright-background: #007fff;
  --bright-background-hover: #0059b2;
  --gray: #3d4752;
  --argument-color: white;
}

body.dark {
  color: white;
  background: #0a1929;

  --dark-background: #001e3c;
  --bright-border: #5090d3;
  --bright-background: #007fff;
  --bright-background-hover: #0059b2;
  --gray: #3d4752;
  --argument-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  color: inherit;
}

/* Oh My God. This is required in Safari, since user-select: none is the 
 * default for all elements, including contenteditables.
 * Source: https://stackoverflow.com/questions/20435166/contenteditable-not-working-in-safari-but-works-in-chrome
*/
[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}
