.argument-color-red {
  background: #ce522c;
  color: white;
  --button-hover-color: rgba(0%, 0%, 0%, 20%);
}

.argument-color-green {
  background: #5ec45e;
  color: white;
  --button-hover-color: rgba(0%, 0%, 0%, 20%);
}

.argument-color-yellow {
  background: #f5db00;
  color: rgb(61, 71, 82);
  --button-hover-color: rgba(50%, 50%, 50%, 20%);
}

.argument-color-lightblue {
  background: #80bdfe;
  color: rgb(61, 71, 82);
  --button-hover-color: rgba(0%, 0%, 0%, 20%);
}

.argument-color-blue {
  background: #0080ff;
  color: white;
  --button-hover-color: rgba(0%, 0%, 0%, 20%);
}

.argument-color-darkblue {
  background: #003e7b;
  color: white;
  --button-hover-color: rgba(100%, 100%, 100%, 50%);
}
