.zen .buttons-right .icon-button:not(.zen-mode-button) {
  transform: translateX(150%);
  opacity: 0%;
}

.buttons-right {
  overflow-x: hidden;
}

.buttons-right .icon-button:not(.zen-mode-button) {
  transition: transform 0.5s, opacity 0.5s;
}

.zen .buttons-left .icon-button {
  transform: translateX(-150%);
  opacity: 0;
}

.zen .legend.legendEmpty {
  transform: translateX(-150%);
  opacity: 0;
}

.zen .legend:not(.legendEmpty) {
  transform: translateY(-180px);
}

.buttons-left * {
  transition: transform 0.5s, opacity 0.5s;
}

.zen .footer-wrapper footer {
  transform: translateY(150%);
  opacity: 0;
}

.footer-wrapper {
  overflow: hidden;
}

footer {
  transition: transform 0.5s, opacity 0.5s;
}
