#modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(0, 0, 0, 80%); */
  display: grid;
  visibility: hidden;
  pointer-events: all;
  overflow-y: scroll;
}
.modal.isActive {
  animation: modal-animation-show 0.2s forwards normal;
}
.modal:not(.isActive) {
  animation: modal-animation-hide 0.2s reverse;
}
@keyframes modal-animation-hide {
  0% {
    visibility: hidden;
    background: transparent;
  }
  100% {
    visibility: visible;
    background: rgba(0, 0, 0, 50%);
  }
}
@keyframes modal-animation-show {
  0% {
    visibility: hidden;
    background: transparent;
  }
  100% {
    visibility: visible;
    background: rgba(0, 0, 0, 50%);
  }
}
@supports (backdrop-filter: blur(20px)) {
  @keyframes modal-animation-hide {
    0% {
      visibility: hidden;
      backdrop-filter: blur(0px);
      background: transparent;
    }
    100% {
      visibility: visible;
      backdrop-filter: blur(20px);
      background: rgba(0, 0, 0, 10%);
    }
  }
  @keyframes modal-animation-show {
    0% {
      visibility: hidden;
      backdrop-filter: blur(0px);
      background: transparent;
    }
    100% {
      visibility: visible;
      backdrop-filter: blur(20px);
      background: rgba(0, 0, 0, 10%);
    }
  }
}
.modal-inner {
  position: relative;
  min-width: 300px;
  max-width: 450px;
  place-self: center;
  border: 1px solid var(--bright-border);
  padding: 20px;
  border-radius: 20px;
  background: var(--dark-background);

  transition: transform 0.3s;
  transform: translateY(100vh);
}
.modal.isActive .modal-inner {
  transform: none;
}
.modal-inner p {
  text-align: justify;
}
.modal .icon-button {
  position: absolute;
  top: 10px;
  right: 10px;
  display: grid;
  place-content: center;
}

.modal h1 {
  margin: 0;
}

@media print {
  footer {
    display: none;
  }
}

footer {
  font-size: 0.8em;
}

footer a {
  padding: 0px 15px;
}

.reference {
  text-align: justify;
  font-size: 0.7em;
}

.modal :not(p) {
  text-align: center;
}
