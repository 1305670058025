.trash-can {
  border: 1px solid var(--bright-border);
  border-radius: 10px;
  background: var(--dark-background);

  width: var(--argument-width);
  height: 40px;

  display: grid;
  place-items: center;
  font-size: 20px;

  color: gray;
}

.trash-can.dragTarget {
  color: red;
}
