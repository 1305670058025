.imprint-columns {
  display: grid;
  text-align: left;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  align-items: center;
}

.imprint-censored {
  color: transparent;
  background: white;
  user-select: none;
}
