.legend {
  text-align: left;
}

.legend-entries {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 0.5em;
  row-gap: 0.5em;
}

.legend-entry {
  display: contents;
}

.legend-entry-box {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  print-color-adjust: exact;
}

.legend-entry-text:empty::after {
  opacity: 30%;
  content: attr(data-placeholder-text);
  cursor: text;
}

@media (max-height: 500px) {
  .legend {
    display: none;
  }
}
