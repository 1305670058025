.App {
  text-align: center;
  display: grid;
  grid-template-rows: auto 1fr auto max(10vw, 70px) 20px;
  width: 100vw;
  height: 100vh;

  overflow: auto;
  position: relative;
}

.App h1 {
  font-weight: bold;
  outline: none;
}

.titles-title {
  overflow: hidden;
  margin-bottom: 30px;
  min-height: 50px;
  margin-left: 70px;
  margin-right: 70px;
}

.buttons-left {
  position: absolute;
  left: 10px;
  top: 10px;
}
.buttons-left > * {
  display: block;
  margin-bottom: 10px;
}

.buttons-right {
  position: absolute;
  top: 10px;
  right: 10px;

  display: grid;
  grid-template-columns: auto;
  gap: 10px;
}

.buttons-right .icon-button {
  width: 30px;
  height: 30px;
  font-size: 1em;
}

.unsorted-list {
  justify-self: center;
  align-self: start;
}

.seesaw {
  align-self: end;

  margin: 0 5vw;
  margin-top: 2vw;
  transition: transform 0.5s ease;
}

.beam {
  background: var(--gray);
  height: 20px;
  width: 100%;
  border-radius: 10px;
  print-color-adjust: exact;
}

.sorted-lists {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  align-items: end;
  justify-items: center;

  padding-bottom: 1px;

  margin: 0 10px;
}

.plus-button {
  display: grid;
  place-content: center;
  text-align: center;
  min-height: 2em;
  cursor: pointer;

  margin: 1px;
  border-radius: 8px;

  word-wrap: break-word;
  overflow: hidden;

  border: 1px solid transparent;
}

.plus-button.isEditing {
  border: 1px dashed var(--bright-border);
}

.plus-button [contentEditable] {
  outline: none;
  min-width: 3px;

  word-wrap: break-word;
}

.plus-button :not([contentEditable]) {
  user-select: none;
}

.bottom-row {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: end;
  margin: 0 35px;
}

.bottom-row .title {
  margin: 10px 10px;
}

.titles-pro {
  text-align: left;
}
.titles-contra {
  text-align: right;
}

.title-input {
  display: inline-block;
  min-width: 1em;
}
.title-stretcher {
  display: inline-block;
}
