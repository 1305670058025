.patterns-enabled .argument-color-red {
  background: repeating-linear-gradient(
      -45deg,
      #00000008,
      #00000008 7px,
      #ffffff08 7px,
      #ffffff08 14px,
      #00000008 14px
    ),
    repeating-linear-gradient(
      45deg,
      #00000008,
      #00000008 7px,
      #ffffff08 7px,
      #ffffff08 14px,
      #00000008 14px
    ),
    #ce522c;
}

.patterns-enabled .argument-color-green {
  background: repeating-linear-gradient(
      0deg,
      #0001,
      #0001 7px,
      #fff1 7px,
      #fff1 14px,
      #0001 14px
    ),
    repeating-linear-gradient(
      90deg,
      #0001,
      #0001 7px,
      #fff1 7px,
      #fff1 14px,
      #0001 14px
    ),
    #5ec45e;
}

.patterns-enabled .argument-color-yellow {
  background: repeating-linear-gradient(
      0deg,
      #0001,
      #0001 7px,
      #fff1 7px,
      #fff1 14px,
      #0001 14px
    ),
    #f5db00;
}

.patterns-enabled .argument-color-lightblue {
  background: repeating-linear-gradient(
      90deg,
      #0001,
      #0001 7px,
      #fff1 7px,
      #fff1 14px,
      #0001 14px
    ),
    #80bdfe;
}

.patterns-enabled .argument-color-blue {
  background: repeating-linear-gradient(
      45deg,
      #0001,
      #0001 7px,
      #fff1 7px,
      #fff1 14px,
      #0001 14px
    ),
    #0080ff;
}

.patterns-enabled .argument-color-darkblue {
  background: repeating-linear-gradient(
      -45deg,
      #0001,
      #0001 7px,
      #fff1 7px,
      #fff1 14px,
      #0001 14px
    ),
    #003e7b;
}
