.argument-color-dropdown-button {
  cursor: pointer;
  border-radius: 6px;
  width: 20px;
  height: 20px;
  display: grid;
  place-content: center;
}

.argument-color-dropdown-button:hover,
.argument-color-dropdown-button.open {
  background: rgba(0%, 0%, 0%, 50%);
  background: var(--button-hover-color);
}

#dropdown-container,
.color-dropdown-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

#dropdown-container:empty {
  display: none;
}

.color-dropdown {
  background: var(--dark-background);
  padding: 2px;
  border-radius: 10px;
  border: 1px solid var(--bright-border);
  display: inline-block;
  position: absolute;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 3px;
}

.color-dropdown-choice {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 8px;
}

.color-dropdown {
  animation: color-dropdown-animation 0.3s ease normal forwards;
}

.edit-text-button {
  grid-column: 1/-1;
  border-radius: 8px;
  border: 1px dashed var(--bright-border);
  cursor: pointer;
  user-select: none;
}

@keyframes color-dropdown-animation {
  0% {
    transform: translateY(-50%) scaleY(0);
  }
  100% {
    transform: none;
  }
}
