.patterns-enabled .switch-patterns-button div {
  width: 15px;
  height: 15px;
  background: repeating-linear-gradient(
    -45deg,
    #0000,
    #0000 3px,
    #fff8 3px,
    #fff8 6px,
    #0000 6px
  );
}

.switch-patterns-button div {
  width: 15px;
  height: 15px;
  background: repeating-linear-gradient(
    -45deg,
    #0000,
    #0000 3px,
    #ffff 3px,
    #ffff 6px,
    #0000 6px
  );
}
