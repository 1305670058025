.list {
  background: var(--dark-background);
  border-radius: 10px;
  min-height: 40px;
  box-sizing: border-box;
  width: var(--argument-width);
  border: 1px solid var(--bright-border);
}

.argument {
  margin: 2px;
  border-radius: 8px;
  user-select: none;

  print-color-adjust: exact;

  word-wrap: break-word;

  position: relative;
  transition: opacity 0.3s, background 0.3s;

  padding: 12px;
  text-align: center;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 3.3em;
  align-content: center;
  align-items: center;
}

.argument .argument-text {
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
}

.argument-text [contentEditable] {
  cursor: text;
}

.argument-text.overflowing {
  mask-image: linear-gradient(black 70%, transparent 100%);
}

.argument-uncolored {
  background: var(--bright-background);
  color: var(--argument-color);
}

.argument::after {
  position: absolute;
  content: "";
  z-index: -1;
  box-shadow: 0 0 5px black;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  opacity: 0;
  transition: opacity 0.3s;
}

.argument.dragged::after {
  opacity: 1;
}

/** 
* react-beautiful-dnd sets the height of the element based on its bounding box. 
* Since we rotate the elements on the seesaw, the bounding box is rotated as 
* well, making the item a little larger than it should be. The height is set on 
* the element itself in a style tag, but we can override this by using
* !important here.
*/
.argument.dragged,
.argument.dropping {
  height: unset !important;
}

.argument.trashed {
  opacity: 0;
}

@import url("colors.css");
